import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../layouts"
import Seo from "../components/seo"
import {BreadCrumb, Section, MarkdownContent} from "../components/Section"
import Content, { HTMLContent } from '../components/Content';
import generateHTML from '../utils/generateHTML';
import CtaSection from '../sections/CtaSection';

const ContentPageTemplate = ({ data, location }) => {
    const pageData = data.contentfulContentPage
    const PostContent = HTMLContent || Content;

    return(
    <Layout footerCta location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <BreadCrumb position="relative">
        <div className="container">
          <Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
          <span>{pageData.metaTitle}</span>
        </div>
      </BreadCrumb>
      <Section bgColor="#F7F5F5" mb="0" mmb="0">
        <div className="container">
          <MarkdownContent>
          <PostContent
            content={generateHTML(pageData.content.childMarkdownRemark.html)}
          />
          </MarkdownContent>
        </div>
      </Section>
      <CtaSection mb="50px" />
    </Layout>
    )
}
      

export default ContentPageTemplate

export const pageQuery = graphql`
  query ContentPageTemplateQuery($id: String!) {
    contentfulContentPage(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`